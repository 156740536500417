@import "settings";

.cookies-notice {
  z-index: 1000;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  padding: 15px 0;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-in-out;
  -webkit-text-size-adjust: 100%;

  .js-is-loaded &.js-is-active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }

  @include breakpoint(medium) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  @include breakpoint(small only) {
    .page-width {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__body {
    padding-right: 15px;
    font-size: 0.875em;
    line-height: 1.5;
    color: #fff;

    @include breakpoint(small only) {
      display: inline-block;
      margin-bottom: 15px;
    }

    @include breakpoint(medium) {
      float: left;
      width: 80%;
      font-size: 0.8125em;
    }

    a {
      color: #fff;
    }
  }

  &__cta {
    @include breakpoint(small only) {
      float: right;
      font-size: 0.875em;
    }

    @include breakpoint(medium) {
      float: left;
      width: 20%;
      text-align: right;
    }
  }
}
