#page-container {
  &::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transition: visibility 0.4s linear, opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateZ(0);
    content: '';
    z-index: 999;

    .js-drawer-open & {
      visibility: visible;
      opacity: 1;
    }
  }

  .js-modal-open & {
    display: none;
  }
}
