@import "settings";

.modal-container {
  display: none;
  min-height: 100vh;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  z-index: -1;
  transition: opacity 150ms;

  @include breakpoint(small only) {
    padding: 0 0 30px;
  }

  &.is-transitioning {
    z-index: 1000;
    position: fixed;
  }

  &.js-is-active {
    display: block;
    opacity: 1;
    visibility: visible;
    z-index: 1000;
  }

  button:-moz-focusring, a:-moz-focusring {
    outline: solid #fff 2px;
  }

  .marketing-checkbox-label {
    color: #fff;
  }
}

.modal-container--lowlight {
  background: #2a2c2e;
  color: #fff;
}

.modal-container--highlight {
  background: #fff;
  color: #2a2c2e;
}

.modal__header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.modal__controls {
  text-align: right;
}

.modal__close {
  margin: 0.625em -0.625em 0 0;
  padding: 0.625em;
  opacity: 0.7;
  transition: opacity 300ms ease;

  &:hover {
    opacity: 1;
  }

  .icon-close-white {
    height: 16px;
    width: 16px;
  }
}

.modal {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000;
  padding-top: 130px;
  position: relative;
  text-align: left;
  transition: all 150ms ease-out;
  transform: scale(0.85);

  @include breakpoint(small only) {
    padding: 60px 30px 0;
  }

  .js-is-active & {
    transform: scale(1);
  }

  &:focus {
    outline: none;
  }
}

.modal__heading {
  color: inherit;
}
