@import "settings";

body {
  cursor: default;
}

.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

.hide {
  display: none;
}

.page-width {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;

  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@include breakpoint(large) {
  .page-width--fluid {
    width: 100%;
    max-width: 1230px;
  }
}
