@import "settings";

.marketing-quote {
  margin: 30px 0;

  &__content {
    font-family: $body-font-family;
    font-weight: 300;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    margin: 0;
    padding: 0.66667em 0 0.66667em 30px;
    font-size: 1.5em;
    color: #000;
    border-left: solid 2px #dfe3e8;
  }

  &__footer {
    text-align: right;
  }
}

.marketing-button, .marketing-nav__button {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: $button-background;
  color: #fff;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  display: inline-block;
  padding: 1.0625em 1.875em;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
  border-radius: 5px;
  color: #fff;
  font-family: $button-font-family;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.133;
  -webkit-font-smoothing: antialiased;
  transition: 150ms ease;
  transition-property: background-color border-color color;
  text-align: center;
  user-select: none;
  appearance: none;
  cursor: pointer;
  text-decoration: none;

  &:hover, &:focus {
    background-color: $button-background-hover;
    color: #fff;
  }

  &:active {
    background-color: #CCCCCC;
    color: #fff;
    border-color: #CCCCCC;
  }

  &:hover {
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  }

  &:focus {
    box-shadow: 0 0 0.1875em 0.1875em rgba(137, 137, 137, 0.5);
    outline: 0;
  }
}

@include breakpoint(medium) {
  .marketing-button + .marketing-button,
  .marketing-nav__button + .marketing-button,
  .marketing-button + .marketing-nav__button,
  .marketing-nav__button + .marketing-nav__button {
    margin-left: 15px;
  }
}

.marketing-button[disabled],
[disabled].marketing-nav__button,
.marketing-form--is-loading .marketing-button,
.marketing-form--is-loading .marketing-nav__button {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #919ea9;
  color: #fff;
  cursor: not-allowed;

  &:hover, &:focus {
    background-color: #CCCCCC;
    color: #fff;
  }

  &:active {
    background-color: #CCCCCC;
    color: #fff;
  }

  &:hover {
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  }

  &:focus {
    box-shadow: 0 0 0.1875em 0.1875em rgba(145, 158, 169, 0.5);
  }

  &:hover, &:focus, &:active {
    background-color: #919ea9;
  }
}

.marketing-form--is-loading button.marketing-button,
.marketing-form--is-loading button.marketing-nav__button {
  position: relative;
  color: #919ea9;

  &::after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -12px;
    margin-top: -12px;
    border-radius: 50%;
    border: 3px solid #fff;
    border-top-color: transparent;
    animation: spin 1s infinite linear;
  }
}

.marketing-button {
  &--white {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    color: #fff;

    &:hover, &:focus {
      background-color: #f0f0f0;
      color: #fff;
    }

    &:active {
      background-color: #e6e6e6;
      color: #fff;
    }

    &:hover {
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    }

    &:focus {
      box-shadow: 0 0 0.1875em 0.1875em rgba(255, 255, 255, 0.5);
    }

    &, &:hover, &:focus {
      color: #30373b;
    }
  }

  &--teal {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #47c1bf;
    color: #fff;

    &:hover, &:focus {
      background-color: #00848e;
      color: #fff;
    }

    &:active {
      background-color: #003135;
      color: #fff;
    }

    &:hover {
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    }

    &:focus {
      box-shadow: 0 0 0.1875em 0.1875em rgba(71, 193, 191, 0.5);
    }
  }

  &--salmon {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fb8b8a;
    color: #fff;

    &:hover, &:focus {
      background-color: #a65c5b;
      color: #fff;
    }

    &:active {
      background-color: #452626;
      color: #fff;
    }

    &:hover {
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    }

    &:focus {
      box-shadow: 0 0 0.1875em 0.1875em rgba(251, 139, 138, 0.5);
    }
  }

  &--lowlight {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #30373b;
    color: #fff;

    &:hover, &:focus {
      background-color: #22272a;
      color: #fff;
    }

    &:active {
      background-color: #191d1f;
      color: #fff;
    }

    &:hover {
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    }

    &:focus {
      box-shadow: 0 0 0.1875em 0.1875em rgba(48, 55, 59, 0.5);
    }
  }

  &--light {
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    color: #000;

    &:hover, &:focus {
      background-color: #f0f0f0;
      color: #000;
    }

    &:active {
      background-color: #e6e6e6;
      color: #000;
    }

    &:hover {
      box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
    }

    &:focus {
      box-shadow: 0 0 0.1875em 0.1875em rgba(255, 255, 255, 0.5);
    }
  }

  &--secondary {
    box-shadow: none;
    background-color: transparent;
    color: $button-background;
    border-width: 0.125em;
    border-style: solid;
    border-color: $button-background;
    padding: 1em 1.75em;

    &:hover, &:focus {
      background-color: $button-background-hover;
      color: #fff;
    }

    &:active {
      background-color: #202e78;
      color: #fff;
      border-color: #202e78;
    }

    &:hover {
      box-shadow: none;
    }

    &:focus {
      box-shadow: 0 0 0.1875em 0.1875em rgba(92, 106, 196, 0.5);
    }
  }

  &--block {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &--reset {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    color: inherit;
    appearance: none;
  }

  &--pill {
    padding-top: 0.3125em;
    padding-bottom: 0.3125em;
    border-radius: 1.875em;
  }
}

.marketing-button--small, .marketing-nav__button {
  padding: 0.8125em 1.875em;
}

.marketing-button--small.marketing-button--secondary,
.marketing-button--secondary.marketing-nav__button {
  padding: 0.6875em 1.75em;
}

.marketing-form--lowlight .marketing-radio-label,
.marketing-form--lowlight .marketing-checkbox-label {
  color: #fff;
}

.marketing-input-wrapper {
  position: relative;
  display: block;
  margin-bottom: 15px;

  .marketing-select-wrapper, .marketing-input, .marketing-textarea {
    margin-bottom: 0;
  }

  &.js-is-hidden {
    display: none;
  }
}

.marketing-input, .marketing-textarea, .marketing-select-wrapper > select {
  display: inline-block;
  height: 52px;
  width: 100%;
  margin: 0 0 15px;
  padding: 1.0625em 1em;
  color: #30373b;
  font-family: $input-font-family;
  font-size: 16px;
  box-shadow: 0 0 0 1px #c4cdd5;
  border: 0;
  border-radius: 5px;
  background-color: #fff;
  background-image: none;
  transition: all 150ms;
  appearance: none;

  .marketing-block--lowlight &,
  .marketing-form--lowlight &,
  .page-block--lowlight & {
    box-shadow: none;
  }

  .js-is-filled & {
    padding-top: 1.375em;
    padding-bottom: 0.375em;
  }

  &:focus, &.focus {
    color: #000;
    outline: 0;
    box-shadow: 0 0 0 2px $button-background;
  }

  &:focus + .marketing-form__messages .error + .suggest,
  &.focus + .marketing-form__messages .error + .suggest {
    display: none;
  }

  &:focus+.marketing-form__messages .error + .is-visible,
  &.focus+.marketing-form__messages .error + .is-visible {
    display: block;
  }

  &[disabled] {
    background-color: #dfe3e8;
  }
}

.marketing-input::placeholder,
.marketing-textarea::placeholder {
  color: #454f5b;
}

.marketing-input--error,
.marketing-block--lowlight .marketing-input--error,
.marketing-form--lowlight .marketing-input--error,
.page-block--lowlight .marketing-input--error {
  box-shadow: 0 0 0 2px #eb644d;
}

.marketing-textarea {
  height: auto;
  min-height: 52px;
}

.marketing-select-wrapper {
  position: relative;
  display: block;
  margin-bottom: 15px;

  & > select {
    margin: 0;
    padding-top: 1.375em;
    padding-bottom: 0.375em;
    padding-right: 3em;
  }

  & > select::-ms-expand {
    display: none;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 2.5em;
    height: 1.25em;
    width: 1px;
    background-color: #c4cdd5;
    transform: translateY(-50%);
  }

  &::before {
    pointer-events: none;
    content: '';
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20baseProfile=%22tiny%22%20viewBox=%220%200%2010.289%206.563%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23919eab%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M5.212%206.563L0%201.423%201.404%200l3.788%203.735L8.865.01l1.424%201.404%22/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    height: 0.75em;
    width: 0.75em;
    margin-bottom: -0.5625em;
    display: block;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
  }
}

.marketing-label {
  display: block;
  margin-bottom: 0.61538em;
  font-family: $body-font-family;
  font-weight: 700;
  font-size: 0.8125em;
  color: #454f5b;

  &--in-field {
    position: absolute;
    left: 16px;
    top: 8px;
    font-size: 0.6875em;
    font-weight: 400;
    pointer-events: none;
    z-index: 10;
  }

  &--floating {
    opacity: 0;
    transform: translateY(3px);
    transition-property: opacity, transform;
    transition-duration: 150ms;

    .js-is-filled & {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &--hidden {
    display: inline;

    .no-placeholder & {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
      position: absolute;
      left: 0;
      top: -20px;
    }
  }
}

.marketing-form {
  &__button {
    height: 52px;
  }

  &__messages {
    font-size: 0.8125em;
    font-weight: 500;

    .error, .success, .suggest {
      display: block;
      padding: 0.61538em 0 0;
    }

    .error {
      color: #eb644d;

      a {
        color: #eb644d;
        border-bottom: 1px solid #eb644d;
      }
    }

    .success {
      display: none;
      color: $button-background;

      a {
        color: #eb644d;
        border-bottom: 1px solid #eb644d;
      }

      .marketing-form--has-success & {
        display: block;
      }
    }

    .suggest {
      display: none;
      color: #637381;

      &.is-visible {
        display: block;
      }

      .marketing-block--lowlight &,
      .marketing-form--lowlight &,
      .page-block--lowlight & {
        color: #fff;
      }
    }
  }

  &__ajax-success {
    display: none;

    &.js-is-visible {
      display: block;
    }
  }

  &__hidden-on-success.js-is-hidden {
    display: none;
  }

  &__fallback-cta {
    color: #454f5b;
  }

  &--inline {
    @include breakpoint(medium) {
      text-align: center;
    }

    .marketing-input-wrapper {
      text-align: left;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }

    &__heading {
      text-align: center;
    }
  }
}

.marketing-checkbox, .marketing-radio {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  margin-bottom: 15px;
  float: left;

  &:checked + label::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
  }

  &:focus + label::before, &:focus:checked + label::before {
    outline: none;
    border-color: $button-background;
    box-shadow: 0 0 4px 0 $button-background;
  }

  &-label {
    display: block;
    text-align: left;
    position: relative;
    margin-bottom: 15px;
    padding-left: 30px;
    line-height: 20px;
    user-select: none;

    &:hover::before {
      border-color: $button-background;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 20px;
      width: 20px;
      background-color: #fff;
      border: 1px solid #c4cdd5;
      border-radius: 5px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
      transition: border-color 150ms ease;
    }

    &--inline {
      margin-right: 0.9375em;
      margin-bottom: 0;
      display: inline-block;
    }
  }

  &--large + label {
    padding-left: 42px;
    line-height: 32px;

    &::before {
      width: 32px;
      height: 32px;
    }
  }
}

.marketing-checkbox {
  &:checked + label::before {
    background-color: $button-background;
    border-color: $button-background;
    box-shadow: none;
  }

  &:checked + label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2040.7%2040%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23fff%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M16.7%2030l-7.4-7.9c-.8-.8-.6-2%20.4-2.6%201-.6%202.4-.5%203.1.3l3.7%204%2011.3-13c.7-.8%202.1-1%203.1-.4s1.2%201.8.5%202.6L16.7%2030z%22/%3E%3C/svg%3E");
    width: 20px;
    height: 20px;
  }

  &--large:checked + label::after {
    width: 32px;
    height: 32px;
  }
}

.marketing-radio {
  &:checked + label::after {
    left: 5px;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: $button-background;
  }

  &:checked + label::before {
    border-color: $button-background;
  }

  &-label::before {
    border-radius: 50%;
  }

  &--large:checked + label::after {
    left: 8px;
    top: 8px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}

@include breakpoint(medium) {
  .marketing-input-button__field-wrapper {
    display: flex;
  }

  .marketing-input-button-pair__input,
  .marketing-input-button-pair__button {
    float: left;
  }

  .marketing-input-button-pair__input {
    flex: 1;
    margin-right: 10px;
  }

  .marketing-input-button-pair__button {
    flex-shrink: 0;
  }
}

@include breakpoint(small only) {
  .marketing-input-wrapper .marketing-input-button-pair__input {
    margin-bottom: 15px;
  }

  .marketing-input-button-pair__button {
    width: 100%;
  }
}

.marketing-input-button__field-wrapper::after {
  content: '';
  display: table;
  clear: both;
}

.no-flexbox {
  .marketing-input-button-pair__button {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  @include breakpoint(medium) {
    .marketing-input-button-pair__button {
      width: 40%;
    }

    .marketing-input-button-pair__input {
      width: 60%;
    }
  }
}
