@import "settings";

.page--home-login, .page--home-forgot-password {
  background: #2a2c2e;
  color: #fff;
}

.login__inline {
  position: absolute;
  top: 0;
  right: 1em;
  line-height: 52px;
  z-index: 10;
}

.login__inline--subdomain {
  pointer-events: none;
  color: $primary-color;
}

.login-form-wrapper {
  width: 100%;
}

.login-form__link {
  color: $primary-color;
  text-decoration: underline;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: currentColor;
  text-decoration-skip: ink;
  transition: color 150ms;

  &:hover, &:focus {
    color: lighten($primary-color, 15%);
  }

  &:focus {
    outline: 0;
    background-color: rgba(92, 106, 196, 0.125);
  }
}

.show-subdomain {
  text-align: right;
  float: right;
  line-height: 1;

  &.js-is-hidden {
    display: none;
  }

  @include breakpoint(small only) {
    button {
      color: #fff;
      border-color: #fff
    }
  }
}

.stateful-form {
  width: 100%;

  .login-field-wrapper {
    position: relative;
  }

  .has-shop-cookie .marketing-label {
    transition: none;
  }

  .subdomain {
    input {
      padding-right: 8.88889em;
    }

    .marketing-input-wrapper::before {
      transition: all 0.2s;
    }

    .js-has-error.js-is-focused::before, .js-has-error.js-is-filled::before {
      transform: translateY(8px);
    }
  }

  .password input {
    padding-right: 3.88889em;
  }
}

.marketing-checkbox-label {
  float: left;
  font-weight: 400;
  color: $primary-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@include breakpoint(small only) {
  .marketing-checkbox-label {
    color: #fff;
  }
}

.forgot-button {
  @include breakpoint(medium) {
    margin-top: 0.9375em;
  }

  @include breakpoint(small only) {
    width: 100%;
  }
}

.forgot-success-wrapper {
  display: none;
  opacity: 0;
  position: absolute;
  transform: translateY(30px);
  transition: 0.85s 0.1s cubic-bezier(0.11, 0.67, 0.2, 1);

  &.js-is-active {
    display: block;
    opacity: 1;
    position: relative;
    transform: translateY(0);
  }
}

.forgot-form-wrapper.js-is-active {
  display: none;
}
