@import "settings";

.announcement {
  padding: 0.9375em;
  text-align: center;
  font-size: 0.875em;
  color: #fff;
  background-color: #2a2c2e;
  display: none;

  &[data-announcement-id="flash_error"] {
    background-color: #ff6666;
  }

  &[data-announcement-id="flash_notice"] {
    background-color: #afd69b;
  }

  &.is-active {
    display: block
  }

  @include breakpoint(small only) {
    position: relative;
    padding-right: 2.675em;
  }

  &__content {
    display: inline-block;
    margin-bottom: 0;
  }

  &__link {
    white-space: nowrap;
  }

  &__icon {
    fill: currentColor;
  }

  &__close {
    margin-left: 15px;

    & > .icon {
      vertical-align: middle
    }

    @include breakpoint(small only) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 0.9375em;
      right: 0;
    }
  }
}
