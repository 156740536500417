[role="button"], input, label, select, textarea {
  touch-action: manipulation;
}

button {
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  display: inline-block;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  touch-action: manipulation;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"] {
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  &:-moz-focusring {
    outline: 1px dotted ButtonText;
  }
}
