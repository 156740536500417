@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin-back {
  0%   { transform: rotate(360deg); }
  100% { transform: rotate(0deg); }
}

@keyframes fade-slide {
  from { opacity: 0; transform: translateY(-30px); }
  30%  { opacity: 0; }
  to   { opacity: 1; transform: translateY(0); }
}
