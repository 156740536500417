@import "settings";
@import "fonts";
@import "animations";
@import "body";
@import "page_container";
@import "cookies";
@import "icon";
@import "button";
@import "announcement";
@import "marketing_form";
@import "modal";
@import "login";

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.showvisuallyhidden,
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.is-transitioning {
  display: block !important;
  visibility: visible !important;
}

.js-focus-hidden:focus {
  outline: none;
}

.signup-modal__content, .partner-modal__content {
  width: 100%;
  margin: 0 auto;

  @include breakpoint(large) {
    max-width: 50%;
  }

  @include breakpoint(medium) {
    max-width: 75% !important;
  }

  @include breakpoint(small only) {
    max-width: 100% !important;
  }

  .marketing-form__button {
    float: right;
  }
}

body > .signup--hidden, body > .partner--hidden {
  display: none;
}

.subdomain {
  position: relative;
}

.stateful-form {
  .marketing-input::-ms-clear {
    display: none;
  }

  .js-has-error {
    .marketing-input {
      box-shadow: 0 0 0 2px #EB644D;
    }

    .marketing-form__messages {
      background-color: #EB644D;
      box-shadow: 0 0 0 2px #EB644D;
      border-radius: 0 0 5px 5px;
      max-height: 90px;

      @include breakpoint(medium) {
        transition: max-height 300ms;
      }
    }
  }

  .js-is-pending {
    &::after {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -9px;
      margin-top: -9px;
      border-radius: 50%;
      border: 3px solid #454F59;
      border-top-color: transparent;
      animation: spin 1s infinite linear;
      z-index: 10;
      right: 1.25em;
      left: auto;
      animation-duration: 0.5s;
    }

    .marketing-input {
      box-shadow: 0 0 0 1px #C4CDD5;
    }

    .marketing-form__messages {
      max-height: 0;
      box-shadow: none;

      @include breakpoint(medium) {
        transition: all 300ms;
      }
    }
  }

  .marketing-input {
    position: relative;
    z-index: 1;
  }

  .js-is-hidden {
    display: none;
  }

  .marketing-form__messages {
    transform: translateY(-2px);
    padding-top: 2px;
    font-size: 1em;
    max-height: 0;
    overflow: hidden;
    display: block;

    @include breakpoint(medium) {
      transition: max-height 300ms;
    }

    .error {
      color: #FFF;
      display: block;
      font-size: 0.75em;
      padding: 0.41667em 1em;
      position: relative;
      min-height: 2em;

      &.hide {
        display: none;
      }

      a {
        color: #FFF;
        text-decoration: underline;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-color: currentColor;
        text-decoration-skip: ink;
        transition: color 150ms;

        &:hover, &:focus {
          color: #F0F0F0;
        }

        &:focus {
          outline: 0;
          background-color: rgba(255, 255, 255, 0.125);
        }
      }
    }
  }
}

.signup-button {
  padding: 1.25rem 1.75rem;
  font-size: 22px;
  border-radius: 8px;
  background: linear-gradient(#FE680C, #FF8300, #FE680C);
  color: #FFF;
  height: auto;

  &:hover {
    background: linear-gradient(#ED610C, #FF8300, #ED610C);
  }
}

a:focus, input:focus {
  outline: none;
}

h3, .h3 {
  color: #FE6501;
}

.main-menu {
  height: 80px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  opacity: 0.98;
  width: 100%;
  border-top: 4px solid #ECECEC;
  background-color: #FFF;
}

.title-bar {
  justify-content: space-between;
}

.side-menu {
  h4 {
    padding: 3rem 1rem 0.7rem 1rem;
    color: #616161;
  }
}

#menu-logo {
  top: -2px;
  position: relative;
  margin-right: 50px;

  a {
    padding: 0;
  }

  span {
    opacity: 0;
    width: 0;
    position: absolute;
  }
}

a.logo {
  width: 205px;
  height: 38px;

  svg {
    height: inherit;
  }
}

.top-menu {
  flex-wrap: nowrap;
}

.top-menu li {
  position: relative;
  padding-top: 20px;

  a:hover {
    &:before {
      content: "";
      width: 100%;
      position: absolute;
      top: -4px;
      left: 0;
      height: 4px;
      background-color: rgba(#565250, 0.2);
    }
  }

  &#menu-logo {
    color: #CCC;

    a:hover {
      &:before {
        top: -40px;
      }
    }
  }

  a {
    padding: 0.625rem 0.60rem;
    font-size: 0.85rem;
  }

  .has-tip {
    border-bottom: none;
    font-weight: normal;
  }

  @include breakpoint(xlarge) {
    a {
      padding: 0.625rem 0.85rem;
      font-size: 1.05rem;
    }
  }

  @include breakpoint(xxlarge) {
    a {
      padding: 0.625rem 1rem;
      font-size: 1.05rem;
    }
  }
}

.top-menu li a.is-active {
  background: transparent;
  color: #524E4C;
}

.top-menu li a.is-active {
  &:before {
    content: "";
    width: 100%;
    position: absolute;
    top: -4px;
    left: 0;
    height: 4px;
    background-color: rgba(#565250, 0.7);
  }
}

.valign-table {
  // .valign-cell {

  // }

  @include breakpoint(medium) {
    display: table;
    .cell {
      display: table-cell;
      vertical-align: middle;
    }

  }
}

.top-menu li#menu-logo a.is-active {
  &:before {
    top: -400px;
  }
}

.title-bar {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: #8C8C8C;
  background: #FFF;
  padding: 1rem 2rem 0.5rem 2rem;

  .menu-link {
    position: absolute;
    right: 1rem;
  }

  .title-bar-title {
    display: none;
  }
}

section {
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 0.85rem;
}

.panel {
  height: 100%;
  width: 100%;
  margin-bottom: 400px;
  background-color: #FFF;
}

.parallaxDiv {
  height: 100%;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px
}

// #parallax1 div.parallaxDiv {
//   background-image: url("example_parallax_bg1.png");
// }

#parallax2 div.parallaxDiv {
  // background-image: url("example_parallax_bg2.png");
  box-shadow: inset 0 0px 14px 0px rgba(136, 171, 64, 0.2901960784313726);
}

// #get-started div.parallaxDiv {
//   background-image: url("get-started-background-2.jpg");
// }

#page_top {
  width: 100%;
  background-color: #666;
  padding: 0;

  div.parallaxDiv {
    // background-image: url("home-banner-01.jpg");
    background-position: center 0;
    padding-top: 5rem;
    padding-bottom: 5rem;

    // &.banner-1 {
      // background-image: url("home-banner-01.jpg");
    // }

    &.banner-2 {
      // background-image: url("home-banner-06.jpg");

      .slider-description {
        color: #FFFFFF;

        h1 {
          text-shadow: #000000 1px 1px 0px;
          color: rgb(255, 255, 255);
        }

        h2 {
          color: rgb(255, 255, 255);
          text-shadow: rgba(0, 0, 0, 0.12) 1px 1px 2px;
        }

        li {
          color: rgb(255, 255, 255);
        }

        .marketing-button {
          background-color: #ffa800 !important;
          font-size: 1rem;

          &:hover {
            background-color: #ff9000 !important;
          }
        }
      }
    }

    &.banner-3 {
      // background-image: url("home-banner-03.jpg");

      .slider-description {
        color: #FFFFFF;

        h1 {
          text-shadow: #000000 1px 1px 0px;
          color: rgb(255, 255, 255);
        }

        h2 {
          color: rgb(255, 255, 255);
          text-shadow: rgba(0, 0, 0, 0.12) 1px 1px 2px;
        }

        li {
          color: rgb(255, 255, 255);
        }

        .marketing-button {
          background-color: #ffa800 !important;
          font-size: 1rem;

          &:hover {
            background-color: #ff9000 !important;
          }
        }
      }
    }
  }

  .slider-description {
    color: rgb(62, 62, 62);

    p, li {
      font-size: 1rem;
    }

    h1 {
      text-shadow: #ffffff 1px 1px 0px;
      font-size: 2.5rem;
      line-height: 1;
      margin-bottom: 15px;
      padding-top: 20px;
      font-weight: bold;
      color: rgb(254, 104, 10);
    }

    h2 {
      font-size: 1.9rem;
      color: rgb(254, 104, 10);
      text-shadow: rgba(0, 0, 0, 0.12) 1px 1px 2px;
    }

    ul {
      list-style: none;
      margin-left: 0;
      margin-bottom: 15px;
    }

    li {
      color: rgb(62, 62, 62);
      background-image: url("tick.png");
      background-repeat: no-repeat;
      background-position: 0 0;
      display: block;
      text-indent: 25px;
      padding-top: 0;
      padding-bottom: 6px;
      font-weight: 500;
      line-height: 1;
    }

    form {
      padding-bottom: 20px;
      text-shadow: none;
    }

    #slideshow {
      text-align: right;
    }

    .marketing-button {
      background-color: #ffa800 !important;
      font-size: 1rem;

      &:hover {
        background-color: #ff9000 !important;
      }
    }
  }

  @include breakpoint(small only) {
    #slideshow {
      padding-top: 30px;
    }
  }
}

.menu.dropdown li {
  display: flex;
  align-items: center;
}

.dropdown.menu a {
  font-size: 14px;
}

.social-media {
  top: 16px;

  a {
    padding: 5px;
  }
}

hgroup {
  width: 100%;
  display: block;
  padding-bottom: 25px;

  .section-number {
    background-image: url("section-number.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    display: inline-block;
    height: 31px;
    width: 31px;
    font-size: 18px;
    text-rendering: optimizeLegibility;
    color: #FFF;
    text-align: center;
    padding-top: 5px;
    line-height: 20px;
    position: relative;
    top: 3px;
  }

  h2 {
    display: inline-block;
    color: #90A213;
    padding-top: 2px;
    padding-left: 10px;
    font-size: 26px;
    max-width: 80%;
  }
}

#spiffy-stores-feature-tour, #frequently-asked-questions {
  .tabs-content {
    border: none;
    width: 100%;
    position: relative;
    z-index: 2;
  }

  .tabs-panel {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    z-index: 1;
    opacity: 0;
    transition: all linear 0.1s;

    &.is-active {
      z-index: 4;
      opacity: 1;
      position: relative;
      transition: all ease-out 0.2s 0.1s;
    }
  }

  .tabs {
    border: none;
  }

  li.tabs-title {
    width: 16%;
    margin-right: 0.666%;
    border: none;
    border-top: 2px solid #A0A0A0;
    background-color: transparent;

    a{
      padding: 1.15rem 0.70rem;
    }

    h4, p {
      color: #A0A0A0;
      line-height: 1.1;
    }

    h4 {
      font-size: 1.1rem
    }

    p {
      font-size: 0.80rem;
    }

    &.is-active {
      border-top: 2px solid #E15E10;
      background-color: transparent;

      a {
        background-color: transparent;
      }

      h4, p {
        color: #F60;
      }
    }

    &:hover {
      border-top: 2px solid #5D5D5D;
      background-color: transparent;

      a {
        background-color: transparent;
      }

      h4, p {
        color: #5D5D5D;
      }
    }
  }

  .tabs h4 {
    font-size: 1.2rem;
    line-height: 1.1;
    display: block;
    padding-bottom: 4px;
    color: #A0A0A0;
  }
}

#frequently-asked-questions, #signup-questions {
  .accordion {
    background: #FFF;
    margin-bottom: 0;
  }

  .accordion-item {
    &.is-active {
      .accordion-title {
        background-color: #FFF;
        color: #666;
      }
    }
  }

  .accordion-title:hover, .accordion-title:focus {
    background-color: #FFF;
  }

  .accordion-title {
    padding: 0.5rem 1rem 0.5rem 0rem;
    border: none;
    font-size: 1rem;
    line-height: 1.2rem;

    &:hover {
      background-color: #FFF;
      color: #666;
    }
  }

  .accordion-content {
    border: none;
    padding: 1rem 0;
    font-size: 0.95rem;
  }
}

#spiffy-stores-blog {
  .blog-image {
    border: 7px solid #FFF;
    z-index: 10;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }

  .blog-image:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  }

  .blog-link {
    display: block;
  }

  .cell.auto {
    padding-left: 1.75rem;
  }

  @include breakpoint(small only) {
    .cell.auto {
      padding-left: 0;
      flex: auto;
    }

    h4 {
      padding-top: 20px;
    }
  }
}

#spiffy-stores-pricing {
  padding-bottom: 0;

  hgroup {
    padding-bottom: 25px;
  }

  h4.subtitle {
    color: #747F27;
    font-size: 18px;
    left: 42px;
    position: relative;
    margin-bottom: 1.5rem;
    max-width: 90%;
  }

  .pricing-table {
    border: none;
    margin-top: 30px;

    th, td {
      text-align: center;
    }

    tbody th, tbody td {
      padding: 0.4rem 0.525rem 0.525rem;
    }

    td:first-child {
      text-align: left;
      max-width: 30%;
      width: 30%;
    }

    thead {
      background-color: #FFF;

      th {
        padding-bottom: 20px;
        max-width: 13rem;

        small {
          font-size: 90%;
          font-weight: normal;
        }

        h2 {
          font-weight: 600;
          color: #FFF;
          margin: 0;

          small {
            font-size: 40%;
            color: inherit;
          }
        }

        h3 {
          font-size: 1.5rem;
          color: #FFF;
          margin: 0;
        }

        h4 {
          font-size: 1.2rem;
          color: #FFF;
          margin: 0;
        }
      }

      th:first-child {
        font-weight: 800;
        background-color: white;
        min-width: 300px;
      }

      th:nth-child(2) {
        background: linear-gradient(#F6EEDE, #FFF);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: #FFF;

        h2 {
          color: #FFF;
          text-shadow: #7D5B1D 1px 1px 2px;
        }

        h3 {
          color: #7D5B1D;
          text-shadow: #FFF 1px 1px 2px;
        }

        h4, small {
          color: #7D5B1D;
          text-shadow: #FFF 1px 1px 2px;
        }

        // small {

        // }
      }

      th:nth-child(3) {
        background: linear-gradient(#ADC96B, #DFEFBB, #FFF);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        top: -10px;
        position: relative;
        color: #789C1B;

        h2 {
          color: #FFF;
          text-shadow: #5E7B11 1px 1px 2px;
        }

        h3 {
          color: #5E7B11;
          text-shadow: #FFF 1px 1px 2px;
        }

        h4, small {
          color: #5E7B11;
          text-shadow: #FFF 1px 1px 2px;
        }
      }

      th:nth-child(4) {
        background: linear-gradient(#EAEFF2, #FFF);
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: #FFF;

        h2 {
          color: #FFF;
          text-shadow: #617D8D 1px 1px 2px;
        }

        h3 {
          color: #617D8D;
          text-shadow: #FFF 1px 1px 2px;
        }

        h4, small {
          color: #617D8D;
          text-shadow: #FFF 1px 1px 2px;
        }
      }
    }

    tbody {
      position: relative;
      top: -10px;

      tr td span.yes {
        display: block;
        width: 100%;
        height: 16px;
        background: transparent url("yes.png") no-repeat center;
      }

      tr td:first-child {
        .has-tip {
          border-bottom: dotted 1px #DEDEDE;
          font-weight: 300;
          cursor: help;
          color: #666;
        }
      }

      tr:nth-child(even) td:first-child {
        background: linear-gradient(to right, #F1F1F1, #FFF);
      }

      tr td:nth-child(2) {
        color: #6E5B3A;
      }

      tr:nth-child(even) td:nth-child(2) {
        background: linear-gradient(to right, #F9F5EB, #FFF);
      }

      tr:nth-child(even) td:nth-child(3) {
        background: linear-gradient(to right, #F7FBEE, #FFF, #F7FBEE);
      }

      tr td:nth-child(3) {
        color: #4A5F14;
      }

      tr:nth-child(even) td:nth-child(4) {
        background: linear-gradient(to right, #FFF, #EAEFF2);
      }

      tr td:nth-child(4) {
        color: #3A4B56;
      }
    }

    thead, tbody, tfoot {
      border: none;
      background-color: white;
    }

    tfoot th:first-child, tfoot td:first-child {
      font-weight: 800;
      background-color: white;
    }

    tfoot td:nth-child(2) {
      background: linear-gradient(#FFF, #F6EEDE);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      color: #6E5B3A;
    }

    tfoot td:nth-child(3) {
      background: linear-gradient(#FFF, rgba(#DFEFBB, 0.4), rgba(#ADC96B, 0.5));
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      bottom: -10px;
      position: relative;
      color: #4A5F14;
    }

    tfoot td:nth-child(4) {
      background: linear-gradient(#FFF, #EAEFF2);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      color: #3A4B56;
    }

    tfoot td {
      button, span, strong {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }
    }
  }

  #pricing-tabs {
    border: none;
    max-width: 90%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;

    li {
      width: 33.33%;

      &.tabs-title > a {
        display: block;
        padding: 1.25rem 1.5rem;
        font-size: 1rem;
        line-height: 1;
        color: #FF6600;
        text-align: center;
      }

      &.tabs-title > a:focus, &.tabs-title.is-active > a {
        background: #FFF;
        color: #FF6600;
        font-weight: bold;
        border-bottom: 2px solid #FF6600;
      }
    }
  }

  .tabs-content {
    border: none;
    background: #FFF;
    color: #0A0A0A;

    .tabs-panel {
      padding: 0;
    }
  }

  .pricing-table-mobile {
    border: none;
    margin-top: 30px;
    max-width: 100%;
    width: 100%;

    th, td {
      text-align: center;
    }

    thead, tbody, tfoot {
      border: none;
      background-color: #FFF;
    }

    tbody th, tbody td {
      padding: 0.4rem 0.525rem 0.525rem;
    }

    th {
      padding-bottom: 20px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      color: #FFF;

      h2 {
        font-weight: 600;
        color: #FFF;
        margin: 0;
        font-size: 2.8rem;

        small {
          font-size: 50% !important;
          color: inherit;
        }
      }

      h3 {
        font-size: 1.5rem;
      }

      h4 {
        font-size: 1.2rem;
        margin: 0;
      }
    }

    tbody {
      tr td span.yes {
        display: block;
        width: 100%;
        height: 16px;
        background: transparent url("yes.png") no-repeat center;
      }

      td:first-child {
        text-align: left;
        max-width: 40%;
        width: 40%;
      }

      tr td:first-child {
        .has-tip {
          border-bottom: dotted 1px #DEDEDE;
          font-weight: 300;
          cursor: help;
          color: #666;
        }
      }

      td:nth-child(2) {
        max-width: 60%;
        width: 60%;
      }

      tfoot td {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        color: #6E5B3A;
        text-align: center !important;

        button, span, strong {
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
    }
  }

  .pricing-table-mobile.startup {
    th {
      background: linear-gradient(#F6EEDE, #FFF);

      h2 {
        text-shadow: #7D5B1D 1px 1px 2px;
      }

      h3 {
        color: #7D5B1D;
        text-shadow: #FFF 1px 1px 2px;
      }

      h4, small {
        color: #7D5B1D;
        text-shadow: #FFF 1px 1px 2px;
      }

      small {
        color: #7D5B1D;
        text-shadow: #FFF 1px 1px 2px;
        font-size: 90%;
        font-weight: normal;
      }
    }

    tbody {
      tr:nth-child(even) td:first-child {
        background: linear-gradient(to right, #F1F1F1, #FFF);
      }

      tr:nth-child(even) td {
        background: linear-gradient(to right, #F9F5EB, #FFF);
      }
    }

    tfoot td {
      background: linear-gradient(#FFF, #F6EEDE);
      color: #6E5B3A;
    }
  }

  .pricing-table-mobile.plus {
    th {
      background: linear-gradient(#ADC96B, #DFEFBB, #FFF);
      color: #789C1B;

      h2 {
        color: #FFF;
        text-shadow: #5E7B11 1px 1px 2px;
      }

      h3 {
        color: #5E7B11;
        text-shadow: #FFF 1px 1px 2px;
      }

      h4 {
        color: #5E7B11;
        text-shadow: #FFF 1px 1px 2px;
      }

      small {
        color: #5E7B11;
        text-shadow: #FFF 1px 1px 2px;
        font-size: 90%;
        font-weight: normal;
      }
    }

    tbody {
      tr:nth-child(even) td:first-child {
        background: linear-gradient(to right, #F1F1F1, #FFF);
      }

      tr:nth-child(even) td {
        background: linear-gradient(to right, #F7FBEE, #FFF, #F7FBEE);
      }
    }

    tfoot td {
      background: linear-gradient(#FFF, rgba(#DFEFBB, 0.4), rgba(#ADC96B, 0.5));
      color: #4A5F14;
    }
  }

  .pricing-table-mobile.pro {
    th {
      background: linear-gradient(#EAEFF2, #FFF);

      h2 {
        color: #FFF;
        text-shadow: #617D8D 1px 1px 2px;
      }

      h3 {
        color: #617D8D;
        text-shadow: #FFF 1px 1px 2px;
      }

      h4 {
        color: #617D8D;
        text-shadow: #FFF 1px 1px 2px;
      }

      small {
        color: #617D8D;
        text-shadow: #FFF 1px 1px 2px;
        font-size: 90%;
        font-weight: normal;
      }
    }

    tbody {
      tr:nth-child(even) td:first-child {
         background: linear-gradient(to right, #F1F1F1, #FFF);
      }

      tr:nth-child(even) td {
        background: linear-gradient(to right, #FFF, #EAEFF2);
      }
    }

    tfoot td {
      background: linear-gradient(#FFF, #EAEFF2);
      color: #3A4B56;
    }
  }
}

#about-spiffy-stores {
  .spiffy-stores-ecommerce {
    padding-left: 30px;
  }

  @include breakpoint(small only) {
    .spiffy-stores-ecommerce {
      padding-left: 0;
    }

    h3, .h3 {
      font-size: 1.8rem;
      padding-top: 20px;
    }
  }
}

#pricing-sign-up {
  text-align: center;
  padding-top: 0;

  h2 {
    color: #FE6501;
    padding-bottom: 30px;
  }

  h2, .button {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .grid-container {
    display: table;
    height: 100%;

    .grid-x {
      display: table-cell;
      vertical-align: middle;
      padding-top: 50px;
      padding-bottom: 50px;
      text-align: center;
    }
  }

  .button {
    padding: 1.25rem 1.75rem;
    font-size: 22px;
    border-radius: 8px;
    background-color: rgb(254, 100, 0);
    box-shadow: 1px 1px 30px rgba(#000, 0.15);
    color: #FFF;
    height: auto;

    &:hover {
      background-color: rgb(254, 140, 0);
      color: #FFF;
      box-shadow: 1px 1px 30px rgba(#000, 0.2);
    }
  }
}

#get-started {
  height: 45vh;
  padding-bottom: 0;

  h1 {
    text-shadow: rgba(#000, 0.45) 2px 2px 9px;
  }

  .grid-container {
    display: table;
    height: 100%;

    .grid-x {
      display: table-cell;
      vertical-align: middle;
      padding-top: 50px;
      padding-bottom: 50px;
      color: #FFF;
      text-align: center;
    }
  }

  .button {
    padding: 1.25rem 1.75rem;
    font-size: 22px;
    border-radius: 8px;
    background-color: #FFF;
    box-shadow: 1px 1px 30px rgba(#000, 0.15);
    color: #656565;
    height: auto;

    &:hover {
      background-color: #FFF;
      color: #FE680C;
      box-shadow: 1px 1px 30px rgba(#000, 0.4);
    }
  }
}

#integration-partners {
  text-align: center;
    h2 {
      color: rgb(144, 162, 19);
    }
    p {
      font-size: 1.2rem;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      color: rgb(120, 120, 120);
    }
}

#signup-footer {
  background-color: #383838;
  background: linear-gradient(#2E2D2D, #2E2D2D, #1E1E1E);
  padding-bottom: 0;

  .grid-container {
    display: table;
    height: 100%;

    .grid-x {
      display: table-cell;
      vertical-align: middle;
      padding-top: 50px;
      padding-bottom: 50px;
      color: #FFF;

      p {
        text-align: center;
        margin-top: 1rem;
        color: #FFFFFF;
      }

      h4 {
        color: #FFFFFF;
      }
    }
  }

  h2, .h2 {
    font-size: 2.5rem;
    color: #FFF;
    margin-bottom: 1rem;
  }

  @include breakpoint(small only) {
    .grid-container {
      width: 90%;
    }
  }
}

#footer {
  padding-top: 60px;
  padding-bottom: 75px;
  background-color: #1F1F1F;
  background: linear-gradient(#1E1E1E, #1B1B1B);
  color: #FFF;
  text-shadow: #000 1px 1px 2px;

  ul {
    list-style-type: none;
    margin: 0;
    margin-bottom: 30px;

    li {
      a {
        color: #FFF;
      }
    }
  }

  h4 {
    color: #FFFFFF;
  }
}

#footer-bottom {
  padding-top: 30px;
  padding-bottom: 15px;
  background-color: #000;
  text-align: center;
  color: #606060;
  p  {
    color: #606060;
  }
}

.scroll-top-wrapper {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  text-align: center;
  z-index: 99999999;
  background-color: #777;
  color: #EEE;
  width: 50px;
  height: 48px;
  line-height: 48px;
  right: 30px;
  bottom: 30px;
  padding-top: 2px;
  border-radius: 5rem;
  transition: all 0.5s ease-in-out;

  &:hover, &:focus {
    background-color: #888;
    color: #EEE;
  }

  &.show {
    visibility:visible;
    cursor:pointer;
    opacity: 1.0;
  }

  i.fa {
    line-height: inherit;
  }
}

.gutter-bottom {
  margin-bottom: 30px;
}

.gutter-bottom--reset {
  margin-bottom: 0 !important;
}

.gutter-bottom--half {
  margin-bottom: 15px;
}

body.page--home-login, body.page--home-forgot-password {
  section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .login-frame-container {
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-frame {
    height: auto;
    background-color: #FFF;
    padding: 35px;
    border-radius: 9px;
    vertical-align: middle;
    color: #4d4d4d;
  }

  .spiffy-stores-logo {
    height: 45px;
    width: 100%;
    margin-bottom: 20px;
    overflow: hidden;
    display: block;
    text-align: center;
    background-image: url("logo.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .login-title {
    height: auto;
    margin-bottom: 2rem;
    font-size: 1.7rem;
    color: #989898;
    border-top: 1px solid #f3f3f3;
    padding-top: 2rem;
  }

  .login-form-wrapper {
    height: auto;
  }

  #page-container {
    background-image: url("login-screen-background-1.jpg");
    background-size: cover;

    &.background-1 {
      background-image: url("login-screen-background-1.jpg");
    }

    &.background-2 {
      background-image: url("login-screen-background-2.jpg");
    }

    &.background-3 {
      background-image: url("login-screen-background-3.jpg");
    }

    &.background-4 {
      background-image: url("login-screen-background-4.jpg");
    }

    &.background-5 {
      background-image: url("login-screen-background-5.jpg");
    }

    &.background-6 {
      background-image: url("login-screen-background-6.jpg");
    }

    &.background-7 {
      background-image: url("login-screen-background-7.jpg");
    }

    &.background-8 {
      background-image: url("login-screen-background-8.jpg");
    }

    &.background-9 {
      background-image: url("login-screen-background-9.jpg");
    }

    &.background-10 {
      background-image: url("login-screen-background-10.jpg");
    }
  }

  .signup-modal__content {
    max-width: 50%;
  }
}

body.page--privacy {
  h2, .h2, p {
    display: block;
    width: 100%;
  }

  h2 {
    margin-top: 2.5rem,
  }
}

body.page--features,
body.page--contact,
body.page--contact_support,
body.page--terms,
body.page--partner_terms,
body.page--gift_card_terms,
body.page--privacy,
body.page--themes,
body.page--design,
body.page--apps,
body.page--zip_pay,
body.page--afterpay,
body.page--making_your_first_sale {
  // #footer {

  // }

  h1 {
    color: #CCC;
    font-size: 2rem;
  }

  h1.header-title {
    color: #696969;
    padding-top: 10px;
    padding-bottom: 45px;
  }

  h2 {
    font-size: 1.5rem;
    color: #fe690c;
  }

  h3 {
    font-size: 1.25rem;
  }

  h1, h2, h3, h4 {
    width: 100%;
  }

  #features, #contact, #terms, #privacy, #theme-documentation, #theme-design, #featured-apps {
    background-image: url("global-background.png");
    background-position: left top;
    background-repeat: repeat-x;
    margin-bottom: 100px;
  }

  .ticks {
    ul {
      margin-left: 0;
    }

    li {
      background-image: url("tick-dark.png");
      background-repeat: no-repeat;
      background-position: 0px 0px;
      margin-left: 0px;
      padding-left: 25px;
      list-style: none;
      text-indent: 0px;
      padding-top: 0px;
      padding-bottom: 5px;
    }
  }

  .app-info {
    padding-top: 2rem;
    .cell.medium-6 {
      padding-bottom: 2rem;
    }

    .app-image {
      width: 80%;
    }

    // h3 {
    // }
  }

  .contact-faqs {
    margin-top: 60px;
  }

  .g-recaptcha {
    padding-bottom: 30px;
  }

  div#flash-message .alert-success {
    padding: 20px;
    border: 1px solid rgb(114, 158, 12);
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: rgb(114, 158, 10);
    color: rgb(255, 255, 255);
    font-size: 1.3rem;
  }

  div#flash-message .alert-error {
    padding: 20px;
    border: 1px solid rgb(164, 9, 9);
    margin-top: 40px;
    margin-bottom: 40px;
    background-color: rgb(226, 4, 4);
    color: rgb(255, 255, 255);
    font-size: 1.3rem;
  }
}

.page--zip_pay, .page--afterpay {
  #page_top {
    text-align: center;
    background-image: none;
    background-color: #CCC;

    div.parallaxDiv {
      // background-image: url("zip_pay/zip_pay_banner_2.jpg");
      min-height: 35em;
    }

    h1 {
      color: rgb(255, 255, 255);
      font-size: 4rem;
      font-weight: 600;
      margin-bottom: 2rem;
      margin-top: 2.5rem;
    }

    img {
      max-width: 90%;
    }

    h3 {
      color: rgb(255, 255, 255);
      font-size: 1.8rem;
      margin-top: 3rem;
    }
  }

  .featured-integration-info {
    text-align: center;

    .parallaxDiv {
      padding-top: 70px;
      padding-bottom: 60px;
    }

    h3 {
      font-size: 2.5rem;
    }

    h4 {
      color: rgb(253, 103, 4);
    }

    p {
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: 1.05rem;
      color: rgb(96, 96, 96);
    }

    .integration-icon {
      width: 80px;
      height: 80px;
      margin-bottom: 15px;
    }
  }

  .circle-step {
    position: relative;
    margin-bottom: 10px;
    z-index: 1;
  }

  .circle-step > span {
    display: inline-block;
    height: 44px;
    width: 44px;
    background-color: rgb(136, 171, 64);
    line-height: 45px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    border-radius: 4px;
    z-index: 0;

    &:before {
      content: "";
      position: absolute;
      width: 105%;
      height: 4px;
      background: rgba(136, 171, 64, 0.32);
      left: 50%;
      top: 20px;
      z-index: -1;
    }
  }

  .circle-step.last > span:before {
    display: none;
  }

  .featured-integration {
    h3 {
      font-size: 2.2rem;
      color: rgb(144, 162, 19);
      line-height: 2.5rem;
      margin-bottom: 2rem;
    }

    p {
      font-size: 1.2rem;
      color: rgb(80, 80, 80);
    }

    .grid-container .grid-x {
      @include breakpoint(medium) {
        display: table;
        .cell {
          display: table-cell;
          vertical-align: middle;
        }
      }
    }

    .button {
      background-color: rgb(136, 171, 64);
      color: rgb(254, 254, 254);
      border-radius: 5px;

      &:hover {
        background-color: rgb(114, 142, 57);
      }
    }

    .integration-image {
      max-width: 95%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }
  }

  .how-it-works {
    h3 {
      color: rgb(136, 171, 64) !important;
      margin-bottom: 2rem;
      font-size: 3rem;
    }
  }

  .seamless-payment-experience {
    @media screen and (max-width: 39.9375em) {
      h3 {
        margin-top: 50px;
      }
    }
  }

  .zip-info {
    @media screen and (max-width: 39.9375em) {
      .integration-image {
        margin-top: 50px;
      }
    }
  }

  .get-started-with-zip {
    // div.parallaxDiv {
    //   background-image: url("zip_pay/zip_pay_banner_5.jpg");
    // }

    .grid-container {
      display: table;
      height: 100%;

      .grid-x {
        display: table-cell;
        vertical-align: middle;
        padding-top: 50px;
        padding-bottom: 50px;
        color: rgb(255, 255, 255);
        text-align: center;
      }
    }

    h1 {
      text-shadow: rgba(0, 0, 0, 0.21) 2px 2px 9px;
      color: rgb(255, 255, 255);
      font-size: 3rem;
    }

    h2 {
      font-size: 2.2rem;
    }

    p {
      color: rgb(255, 255, 255);
      font-size: 1.6rem;
      text-shadow: rgba(0, 0, 0, 0.47) 2px 2px 5px;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 3rem;
    }

    .button {
      padding: 1.25rem 1.75rem;
      font-size: 22px;
      border-radius: 8px;
      background-color: rgb(255, 255, 255);
      -webkit-box-shadow: 1px 1px 30px rgba(0,0,0,0.15);
      box-shadow: 1px 1px 30px rgba(0,0,0,0.15);
      color: rgb(101, 101, 101);
      height: auto;

      &:hover {
        box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.78);
        color: rgb(55, 55, 55);
      }
    }
  }
}

.page--afterpay {
  // #page_top div.parallaxDiv {
  //   background-image: url("afterpay/afterpay_banner_1.jpg");
  // }
  .featured-integration {
    h3 {
      color: #fb6503;
    }
    .button {
      background-color: #ff690b;
    }
  }

  #parallax2 div.parallaxDiv {
    // background-image: url("afterpay/afterpay_parallax_bg2.png");
    box-shadow: inset 0 0px 14px 0px rgba(64, 171, 152, 0.08);
  }

  .how-it-works {
    h3 {
      color: #44a281 !important;
    }
    .circle-step>span {
      background-color: #44a281;
    }
    .circle-step>span:before {
      background-color: rgba(68, 162, 129, 0.2)
    }
  }
}

#inline-signup-body {
  padding-top: 5rem;
  padding-bottom: 5rem;

  // h2 {
  // }

  p {
    text-align: center;
    margin-top: 1rem;
    color: rgb(125, 125, 125);
  }
}

section.payment-providers {
  padding-top: 3rem;
  padding-bottom: 5rem;
  text-align: center;

  h2 {
    font-size: 2.4rem;
    margin-bottom: 1rem;
    color: rgb(254, 105, 12);
  }

  p {
    font-size: 1.2rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    color: rgb(120, 120, 120);
  }

  ul {
    li {
      list-style-type: none;
      color: rgb(87, 87, 87);
      line-height: 2rem;

      // a, a:hover, a:visited {
      // }
    }
  }
}

body.page--partner_home, body.page--partner_register {
  #page_top {
    text-align: left;
    background-image: none;
    background-color: #666;

    div.parallaxDiv {
      background-image: url("partners/spiffy-stores-partners-banner-8.jpg");
      background-position: center 0px;
    }

    h1 {
      /* color: rgb(133, 152, 3); */
      color: #FFF;
      font-size: 2.7rem;
      font-weight: 600;
      margin-bottom: 1rem;
      margin-top: 2.5rem;
    }

    h3 {
      /* color: rgb(101, 101, 101); */
      color: #FFF;
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }

    .signup-button {
      background-color: rgb(144, 162, 19) !important;
      background: linear-gradient(rgb(144, 162, 19), rgb(157, 173, 53), rgb(144, 162, 19));

      &:hover {
        background-color: rgb(120, 136, 14) !important;
        background: linear-gradient(rgb(134, 151, 19), rgb(152, 172, 17), rgb(128, 145, 14));
      }
    }

    .banner-signup {
      margin-bottom: 4rem;
    }
  }

  .partner-intro {
    h2 {
      font-size: 2.2rem;
      color: rgb(144, 162, 19);
      line-height: 2.5rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: rgb(80, 80, 80);
    }

    .button {
      background-color: rgb(136, 171, 64);
      color: rgb(254, 254, 254);
      border-radius: 5px;

      &:hover {
        background-color: rgb(114, 142, 57);
      }
    }

    .integration-image {
      max-width: 95%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    .intro-points {
      h4 {
        font-size: 1.3rem;
        color: rgb(144, 162, 19);
      }
      p {
        font-size: 0.9rem;
        line-height: 1.5rem;
      }
      .cell {
        padding-right: 2rem;
      }
    }
  }

  .partner-benefits {
    text-align: center;

    .parallaxDiv {
      padding-top: 70px;
      padding-bottom: 60px;
    }

    h2 {
      font-size: 2.5rem;
      color: rgb(253, 102, 5);
      margin-bottom: 3rem;
    }

    h4 {
      color: rgb(253, 103, 4);
    }

    p {
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: 1.05rem;
      color: rgb(96, 96, 96);
    }

    .integration-icon {
      width: 80px;
      height: 80px;
      margin-bottom: 15px;
    }
  }

  .partner-grow {
    h2 {
      font-size: 2.2rem;
      color: rgb(253, 102, 5);
      line-height: 2.5rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: rgb(80, 80, 80);
    }

    .button {
      background-color: rgb(136, 171, 64);
      color: rgb(254, 254, 254);
      border-radius: 5px;

      &:hover {
        background-color: rgb(114, 142, 57);
      }
    }

    .integration-image {
      max-width: 95%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    .info-points {
      h4 {
        font-size: 1.2rem;
        color: rgb(253, 102, 5);
      }
      p {
        font-size: 0.9rem;
        line-height: 1.5rem;
      }

      .cell {
        padding-right: 2rem;
      }
    }
  }

  .integration-partner {
    div.parallaxDiv {
      background-image: url("partners/integration-partner-background.jpg");
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    .grid-container {
      display: table;
      height: 100%;

      .grid-x {
        display: table-cell;
        vertical-align: middle;
        padding-top: 50px;
        padding-bottom: 50px;
        color: rgb(255, 255, 255);
        text-align: center;
      }
    }

    h2 {
      color: #FFF;
      font-size: 2.6rem;
      font-weight: 600;
      text-shadow: rgba(0, 0, 0, 0.21) 2px 2px 9px;
    }

    p {
      font-size: 1.5rem;
      text-shadow: rgba(0, 0, 0, 0.47) 2px 2px 5px;
    }

    .button {
      padding: 1.25rem 1.75rem;
      font-size: 22px;
      border-radius: 8px;
      background-color: rgb(255, 255, 255);
      background: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255), rgb(255, 255, 255));
      text-shadow: none;
      box-shadow: 1px 1px 30px rgba(0,0,0,0.15);
      color: rgb(101, 101, 101);
      height: auto;

      &:hover {
        box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.78);
        color: rgb(55, 55, 55);
      }
    }
  }

  #inline-signup-body {
    text-align: left;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px dashed rgb(227, 227, 227);

    h2 {
      font-size: 2rem;
      color: rgb(144, 162, 19);
    }

    h4 {
      color: rgb(99, 99, 99);
      font-size: 1rem;
      text-align: left;
    }

    .marketing-button {
      background-color: rgb(144, 162, 19);

      &:hover {
        background-color: rgb(120, 136, 14);
      }
    }

    form {
      margin-top: 2rem;
    }

    .signup-button {
      background-color: rgb(144, 162, 19) !important;
      background: linear-gradient(rgb(144, 162, 19), rgb(157, 173, 53), rgb(144, 162, 19));

      &:hover {
        background-color: rgb(120, 136, 14) !important;
        background: linear-gradient(rgb(134, 151, 19), rgb(152, 172, 17), rgb(128, 145, 14));
      }
    }
  }

  .integration-partners {
    padding-top: 3rem;
    text-align: center;

    h2 {
      color: rgb(144, 162, 19);
    }

    p {
      font-size: 1.2rem;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      color: rgb(120, 120, 120);
    }
  }

  .partner-sign-up {
    text-align: center;
    padding-top: 0;

    h2 {
      color: rgb(144, 162, 19);
      padding-bottom: 30px;
      font-size: 2.5em;
    }

    h2, .button {
      margin-left: auto;
      margin-right: auto;
      display: block;
    }

    .grid-container {
      display: table;
      height: 100%;

      .grid-x {
        display: table-cell;
        vertical-align: middle;
        padding-top: 50px;
        padding-bottom: 50px;
        text-align: center;
      }
    }

    .signup-button {
      padding: 1.25rem 1.75rem;
      font-size: 22px;
      border-radius: 8px;
      background: linear-gradient(rgb(144, 162, 19), rgb(157, 173, 53), rgb(144, 162, 19));
      color: #FFF;
      height: auto;

      &:hover {
        background: linear-gradient(rgb(134, 151, 19), rgb(152, 172, 17), rgb(128, 145, 14));
      }
    }
  }

  .partner-form {
    .marketing-button {
      padding: 1.25rem 1.75rem;
      border-radius: 8px;
      background: linear-gradient(rgb(144, 162, 19), rgb(157, 173, 53), rgb(144, 162, 19));
      color: #FFF;
      height: auto;

      &:hover {
        background: linear-gradient(rgb(134, 151, 19), rgb(152, 172, 17), rgb(128, 145, 14));
      }
    }
  }
}

#reviews {
  padding-bottom: 0px;
  margin-bottom: -25px;

  // .parallaxDiv {
  //   background-image: url("example_parallax_bg1.png");
  // }

  h2 {
    text-align: center;
    font-size: 2.2rem;
    color: rgb(70, 68, 68);
  }

  .review {
    display: table-cell;
    vertical-align: middle;
    font-size: 1.3rem;
    color: rgb(118, 115, 115);
    line-height: 1.9rem;
    font-family: "MuseoSlab500", "Roboto Slab", Georgia, "Times New Roman", serif;
    height: 100%;
    text-align: center;
  }

  .big-review {
    font-size: 0.95rem;
    line-height: 1.3rem;
  }

  .orbit-bullets button {
    width: 0.7rem;
    height: 0.7rem;
    margin: 0.1rem;
    outline: none;
  }

  .orbit-container {
    height: 15rem;
  }

  .orbit-slide {
    height: 100%;
  }

  .orbit-figure {
    height: 100%;
    display: table;
  }

  .orbit-previous {
    left: -6rem;
  }

  .orbit-next {
    right: -6rem;
  }

  .orbit-previous, .orbit-next {
    color: rgb(203, 203, 203);
    outline: none;
  }
}

.page--getting_started_guide {
  p {
    color: #350608;
    font-size: 1.2em;
    font-weight: 100;
  }

  h2 {
    color: #350608;
    font-size: 1.9em;
    font-weight: 300
  }

  h4 {
    color: #350608;
    font-size: 1.6em;
    font-weight: 300
  }

  i {
    font-family: 'MuseoSlab500';
  }

  .links-container {
    position: relative;
  }

  .links {
    width: 90%;
    padding: 2rem;
    background-image: url("getting-started-guide/step-one-video-guides-background.svg");
    position: absolute;
    bottom: 0;
    margin-bottom: -3em;

    @media screen and (max-width: 39.9375em) {
      position: relative;
      margin-top: 2em;
      margin-bottom: 2em;
    }


    h5,
    a {
      color: #FF3600;
    }

    ul {
      list-style-type: none;
      margin-left: 0;
    }

    li {
      margin: 0 0 0 30px;
      padding: 0;
      a {
        margin-left: 2px;
        position: relative;
        border-bottom: none;
        padding-bottom: 7px;
        display: block;

        &:before {
          content: "";
          width: 17px;
          height: 17px;
          background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
          background-size: contain;
          position: absolute;
          left: -30px;
          top: 2px;
          display: block;
          background-repeat: no-repeat;
        }
      }
    }
  }

  #page_top {
    background-color: #ECEBDF;
    padding-top: 3em;
    padding-bottom: 3em;

    h1 {
      text-align: center !important;
      color: #FF5B00;
      padding-bottom: 0.75em;
    }

    p {
      font-size: 1.4em;
      font-weight: 300;
    }
  }

  #section-one {
    background-color: #227574;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    #GuideSignupButton {
      font-family: 'MuseoSlab500';
      padding: 1rem 1.25rem;
      font-size: 18px;
      border-radius: 8px;
      background-color: #4aa0a0;
      box-shadow: 1px 1px 30px #00000026;
      color: #FFF;
      height: auto;
      &:hover {
        background-color: #34adad;
        box-shadow: 1px 1px 30px #00000036;
      }
    }
  }

  #section-two {
    background-color: #ECEBDF;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #350608;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #350608;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #350608;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FF3600;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-three {
    background-color: #EF610E;
    background-image: url("getting-started-guide/step-three-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FFFFFF;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-four {
    background-color: #EFE7DE;
    background-image: url("getting-started-guide/step-four-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #350608;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #350608;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-five {
    background-color: #4B7D1B;
    background-image: url("getting-started-guide/step-five-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FFFFFF;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-six {
    background-color: #ECEBDF;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #350608;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #350608;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #350608;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FF3600;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-six-link-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-seven {
    background-color: #EF610E;
    background-image: url("getting-started-guide/step-seven-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FFFFFF;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-eight {
    background-color: #ECEBDF;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #350608;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #350608;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #350608;
      }
    }

    ul {
      margin: 0 0 0 30px;

      h5,
      a,
      li {
        color: #350608;
      }

      li {
        padding-bottom: 7px;
        position: relative;
        display: block;
        font-size: 1.1em;

        &:before {
          content: "";
          width: 17px;
          height: 17px;
          background-image: url("getting-started-guide/step-eight-tick-icons.svg");
          background-size: contain;
          position: absolute;
          left: -30px;
          top: 2px;
          display: block;
          background-repeat: no-repeat;
        }

      }
    }
  }

  #section-nine {
    background-color: #4B7D1B;
    background-image: url("getting-started-guide/step-nine-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FFFFFF;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-ten {
    background-color: #ECEBDF;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #350608;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #350608;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #350608;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FF3600;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-six-link-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-eleven {
    background-color: #4B7D1B;
    background-image: url("getting-started-guide/step-eleven-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: none;

      display: unset;
      font-weight: 300;

      &:hover {
        text-shadow: 1px 1px 5px #324c04;
      }
    }
  }
}


.page--making_your_first_sale {
  p {
    color: #350608;
    font-size: 1.2em;
    font-weight: 100;
  }

  h2 {
    color: #350608;
    font-size: 1.9em;
    font-weight: 300
  }

  h4 {
    color: #350608;
    font-size: 1.6em;
    font-weight: 300
  }

  i {
    font-family: 'MuseoSlab500';
  }

  .links-container {
    position: relative;
  }

  .links {
    width: 90%;
    padding: 2rem;
    background-image: url("getting-started-guide/step-one-video-guides-background.svg");
    position: absolute;
    bottom: 0;
    margin-bottom: -3em;
    @media screen and (max-width: 39.9375em) {
      position: relative;
      margin-top: 2em;
      margin-bottom: 2em;
    }

    h5,
    a {
      color: #FF3600;
    }

    ul {
      list-style-type: none;
      margin-left: 0;
    }

    li {
      margin: 0 0 0 30px;
      padding: 0;
      a {
        margin-left: 2px;
        position: relative;
        border-bottom: none;
        padding-bottom: 7px;
        display: block;

        &:before {
          content: "";
          width: 17px;
          height: 17px;
          background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
          background-size: contain;
          position: absolute;
          left: -30px;
          top: 2px;
          display: block;
          background-repeat: no-repeat;
        }
      }
    }
  }

  #page_top {
    background-color: #ECEBDF;
    padding-top: 3em;
    padding-bottom: 3em;

    h1 {
      text-align: center !important;
      color: #7d1706;
      padding-bottom: 0.75em;
      font-size: 3rem;
    }

    p {
      font-size: 1.4em;
      font-weight: 300;
    }

    img {
      margin-left: 3em;
    }

  }

  #section-one {
    background-color: #227574;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FFFFFF;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("making-your-first-sale/step-one-link-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-two {
    background-color: #ECEBDF;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #350608;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #350608;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #350608;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FF3600;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-three {
    background-color: #2F7591;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FFFFFF;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-four {
    background-color: #EFE7DE;
    background-image: url("getting-started-guide/step-four-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #350608;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #350608;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-five {
    background-color: #4B7D1B;
    background-image: url("getting-started-guide/step-five-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FFFFFF;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-six {
    background-color: #ECEBDF;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #350608;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #350608;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #350608;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FF3600;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-six-link-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-seven {
    background-color: #EF610E;
    background-image: url("getting-started-guide/step-seven-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FFFFFF;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-eight {
    background-color: #ECEBDF;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #350608;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #350608;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #350608;
      }
    }

    ul {
      margin: 0 0 0 30px;

      h5,
      a,
      li {
        color: #350608;
      }

      li {
        padding-bottom: 7px;
        position: relative;
        display: block;
        font-size: 1.1em;

        &:before {
          content: "";
          width: 17px;
          height: 17px;
          background-image: url("getting-started-guide/step-eight-tick-icons.svg");
          background-size: contain;
          position: absolute;
          left: -30px;
          top: 2px;
          display: block;
          background-repeat: no-repeat;
        }

      }
    }
  }

  #section-nine {
    background-color: #4B7D1B;
    background-image: url("getting-started-guide/step-nine-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #ffffff54;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #ffffff9e;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FFFFFF;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-one-video-guide-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-ten {
    background-color: #ECEBDF;
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #350608;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: 1px dashed #350608;
      padding-bottom: 3px;
      display: unset;
      font-weight: 300;

      &:hover {
        border-bottom: 1px solid #350608;
      }
    }

    .links {
      background-image: none;

      h5,
      a {
        color: #FF3600;
      }

      li {
        a {
          border-bottom: none;
          padding-bottom: 7px;
          display: block;

          &:before {
            content: "";
            width: 17px;
            height: 17px;
            background-image: url("getting-started-guide/step-six-link-icons.svg");
            background-size: contain;
            position: absolute;
            left: -30px;
            top: 2px;
            display: block;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  #section-eleven {
    background-color: #4B7D1B;
    background-image: url("getting-started-guide/step-eleven-background.svg");
    padding-top: 3em;
    padding-bottom: 3em;
    font-family: "MuseoSlab300", "Roboto Slab", Georgia, "Times New Roman", serif;

    p,
    a,
    h2,
    h4 {
      color: #FFFFFF;
    }

    img {
      max-width: 90%;
      @media screen and (max-width: 39.9375em) {
        max-width: 100%;
      }
    }

    a,
    .has-tip {
      text-decoration: none;
      border-bottom: none;

      display: unset;
      font-weight: 300;

      &:hover {
        text-shadow: 1px 1px 5px #324c04;
      }
    }
  }
}

// Fix for overflow on mobiles

@media screen and (max-width: 768px) {

  #reviews .orbit-controls {
    display: none;
  }

  section .grid-container {
    padding-left: 25px;
    padding-right: 25px;
  }

}

@media screen and (max-width: 821px) {
  #page_top div.parallaxDiv.banner-2 {
    background-image: none !important;
    background-color: #ff6700 !important;
    background: radial-gradient(circle, rgba(255,164,0,1) 0%, rgba(245,140,7,1) 37%, rgba(255,79,0,1) 100%) !important;

    .slider-description h1 {
      text-shadow: #ce5403 1px 1px 0px;
    }
  }
}

.signup-modal__content h2.modal__heading {
  & + .hint {
    font-size: 16px;
    color: #FFF;
    padding: 0 0 10px;
  }
}
