// @import url('https://fonts.googleapis.com/css?family=Roboto+Slab');

@font-face {
    font-family: 'MuseoSlab500';
    src: url('museo_slab_500-webfont.eot');
    src: url('museo_slab_500-webfont.eot?#iefix') format('embedded-opentype'),
         url('museo_slab_500-webfont.woff') format('woff'),
         url('museo_slab_500-webfont.ttf') format('truetype'),
         url('museo_slab_500-webfont.svg#MuseoSlab500') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'MuseoSlab300';
    src: url('museo_slab_300-webfont.eot');
    src: url('museo_slab_300-webfont.eot?#iefix') format('embedded-opentype'),
         url('museo_slab_300-webfont.woff') format('woff'),
         url('museo_slab_300-webfont.ttf') format('truetype'),
         url('museo_slab_300-webfont.svg#MuseoSlab300') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'MuseoSlab300Italic';
    src: url('museo_slab_300italic-webfont.eot');
    src: url('museo_slab_300italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('museo_slab_300italic-webfont.woff') format('woff'),
         url('museo_slab_300italic-webfont.ttf') format('truetype'),
         url('museo_slab_300italic-webfont.svg#MuseoSlab300Italic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'MuseoSlab100';
    src: url('museo_slab_100-webfont.eot');
    src: url('museo_slab_100-webfont.eot?#iefix') format('embedded-opentype'),
         url('museo_slab_100-webfont.woff') format('woff'),
         url('museo_slab_100-webfont.ttf') format('truetype'),
         url('museo_slab_100-webfont.svg#MuseoSlab100') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face{
  font-family: "MuseoSlab100Italic";
  src:url('museo_slab_100italic-webfont.eot?#iefix') format(embedded-opentype),
      url('museo_slab_100italic-webfont.woff') format(woff),
      url('museo_slab_100italic-webfont.ttf') format(truetype),
      url('museo_slab_100italic-webfont.svg#MuseoSlab100Italic') format(svg);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?tqqbuf');
  src:  url('icomoon.eot?tqqbuf#iefix') format('embedded-opentype'),
    url('icomoon.ttf?tqqbuf') format('truetype'),
    url('icomoon.woff?tqqbuf') format('woff'),
    url('icomoon.svg?tqqbuf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  // speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-american-express:before {
  content: "\41";
}
.icon-bitcoin:before {
  content: "\42";
}
.icon-cirrus:before {
  content: "\43";
}
.icon-discover:before {
  content: "\44";
}
.icon-fancy:before {
  content: "\46";
}
.icon-google-wallet:before {
  content: "\47";
}
.icon-interac:before {
  content: "\49";
}
.icon-jcb:before {
  content: "\4a";
}
.icon-master:before {
  content: "\4d";
}
.icon-paypal:before {
  content: "\50";
}
.icon-stripe:before {
  content: "\53";
}
.icon-twitter:before {
  content: "\54";
}
.icon-visa:before {
  content: "\56";
}
.icon-diners_club:before {
  content: "\63";
}
.icon-dankort:before {
  content: "\64";
}
.icon-google:before {
  content: "\67";
}
.icon-instagram:before {
  content: "\69";
}
.icon-maestra:before {
  content: "\6d";
}
.icon-pinterest:before {
  content: "\70";
}
.icon-rss:before {
  content: "\72";
}
.icon-search:before {
  content: "\73";
}
.icon-tumblr:before {
  content: "\74";
}
.icon-vimeo:before {
  content: "\76";
}
.icon-youtube:before {
  content: "\79";
}
.icon-cart:before {
  content: "\e600";
}
.icon-hamburger:before {
  content: "\e601";
}
.icon-minus:before {
  content: "\e602";
}
.icon-grid-view:before {
  content: "\e603";
}
.icon-list-view:before {
  content: "\e604";
}
.icon-plus:before {
  content: "\e605";
}
.icon-cross:before {
  content: "\e606";
}
.icon-drop-down:before {
  content: "\e607";
}
.icon-chevron-left:before {
  content: "\f053";
}
.icon-chevron-right:before {
  content: "\f054";
}
.icon-arrow-left:before {
  content: "\f060";
}
.icon-arrow-right:before {
  content: "\f061";
}
.icon-arrow-up:before {
  content: "\f062";
}
.icon-arrow-down:before {
  content: "\f063";
}
.icon-phone:before {
  content: "\f095";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-github:before {
  content: "\f09b";
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-bubbles:before {
  content: "\e96c";
}
.icon-appleinc:before {
  content: "\eabe";
}
.icon-linkedin:before {
  content: "\eaca";
}
