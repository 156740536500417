@import "settings";

.icon {
  display: inline-block;
  height: 1em;
  width: 0.8em;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  &--size-large {
    width: 60px;
    height: 60px;
  }

  &--size-small {
    width: 40px;
    height: 40px;
  }

  &--social {
    width: 30px;
    height: 30px;
  }

  &--primary {
    fill: $primary-color;
  }

  &--grey {
    fill: #919ea9;
  }

  &--black {
    fill: #000;
  }

  &--white {
    fill: #fff;
  }

  &--yellow {
    fill: #fdd991;
  }

  &--green {
    fill: #58b44b;
  }

  &--blue {
    fill: #368aa9;
  }

  &--blue_light {
    fill: #479ccf;
  }

  &--blue_fb {
    fill: #385a98;
  }

  &--blue_twitter {
    fill: #55acee;
  }

  &--blue_linkedin {
    fill: #0976b4;
  }

  &--red_pinterest {
    fill: #bd081c;
  }

  &--fill-primary {
    fill: $primary-color;
  }

  &--fill-grey {
    fill: #919ea9;
  }

  &--fill-black {
    fill: #000;
  }

  &--fill-white {
    fill: #fff;
  }

  &--fill-yellow {
    fill: #fdd991;
  }

  &--fill-green {
    fill: #58b44b;
  }

  &--fill-blue {
    fill: #368aa9;
  }

  &--fill-blue_light {
    fill: #479ccf;
  }

  &--fill-blue_fb {
    fill: #385a98;
  }

  &--fill-blue_twitter {
    fill: #55acee;
  }

  &--fill-blue_linkedin {
    fill: #0976b4;
  }

  &--fill-red_pinterest {
    fill: #bd081c;
  }

  &-arrow-left {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2024%2011%22%3E%3Cpath%20d=%22M5.5%200c.1%200%20.3%200%20.4.1.1.2.1.6-.1.8L1.2%205.5l4.6%204.6c.2.2.2.5%200%20.7-.2.2-.5.2-.7%200l-5-5c-.1-.2-.1-.5%200-.7l5-5c.1-.1.3-.1.4-.1z%22/%3E%3Cpath%20d=%22M.5%205h23c.2%200%20.5.2.5.5s-.3.5-.5.5H.5C.2%206%200%205.8%200%205.5S.2%205%20.5%205z%22/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }

  &-arrow-right {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2024%2011%22%3E%3Cpath%20d=%22M18.5%2011c-.1%200-.3%200-.4-.1-.2-.2-.2-.5%200-.7l4.6-4.6L18.1%201c-.2-.2-.2-.5%200-.7.2-.2.5-.2.7%200l5%205c.2.2.2.5%200%20.7l-5%205c-.1-.1-.2%200-.3%200z%22/%3E%3Cpath%20d=%22M23.5%206H.5C.2%206%200%205.8%200%205.5S.2%205%20.5%205h23c.2%200%20.5.2.5.5s-.3.5-.5.5z%22/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }

  &-close-white {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2044%2044%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23fff%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M39.196%2043.3L1.154%205.256l3.89-3.89%2038.04%2038.043z%22/%3E%3Cpath%20d=%22M.54%2039.413L38.58%201.37l3.89%203.89L4.428%2043.302z%22/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }

  &-close-black {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2044%2044%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23000%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M39.196%2043.3L1.154%205.256l3.89-3.89%2038.04%2038.043z%22/%3E%3Cpath%20d=%22M.54%2039.413L38.58%201.37l3.89%203.89L4.428%2043.302z%22/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }

  &-close {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2044%2044%22%3E%3Cpath%20d=%22M39.196%2043.3L1.154%205.256l3.89-3.89%2038.04%2038.043z%22/%3E%3Cpath%20d=%22M.54%2039.413L38.58%201.37l3.89%203.89L4.428%2043.302z%22/%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
}
